import { toast } from 'react-toastify';

import type { WebchatMessage } from 'types/entities';

export const showChatMessageNotification = (message: WebchatMessage) => {
  const icon = <i className="lnr lnr-bubble" />;

  const element = (
    <div className="text-left">
      <span className="notification-icon pull-left margin-right">
        {icon}
        <i className="ll-icon ll-logo-eva-small fa-2x text-primary" />
      </span>
      <h4 className="margin-left pull-left text-info">{translate('You have a new message!')}</h4>
      <div className="clearfix" />
      <p>&quot;{message.content}&quot;</p>
      <div>
        <a key={message.messageId} rel="noopener noreferrer" href="/candidate/chat">
          {translate('Go to chat')}&nbsp;
          {'>>'}
        </a>
      </div>
    </div>
  );

  return toast(element, {
    delay: 100,
  });
};
